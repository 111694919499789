<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />

        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش تگ
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";
import moment from "jalali-moment";

export default {
  components: {
    VFormBase,
    MySnackbar,
  },
  created() {
    this.getData();
    this.getTags();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        name: "",
        slug: "",
        parent_id: "",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "نام تگ",
          rules: [Constants.rules.required],
          required: true,
        },
        parent_id: {
          type: "CustomInput",
          inputType: "select",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "والد",
          loading: true,
          required: true,
        },
        slug: {
          type: "CustomInput",
          label: "اسلاگ",
          rules: [Constants.rules.required],
          required: true,
          col: {
            cols: 12,
          },
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/tags/index/" + this.id, {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.title = response.data[0].name;
          formGetPrepare(response, this.model);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getTags() {
      MyAxios.get("/tags/index", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.schema.parent_id = {
            ...this.schema.parent_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.name,
            })),
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = formPostPrepare(this.model);
        MyAxios.post(`tags/${this.id}/update`, formData)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "تگ با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            
            if (parseInt(error.response.status) === 500) {
              this.$root.$emit("toast", {
                text:
                  "اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است",
              });
            } else {
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.",
              });
            }
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
